


























































import { Component, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import UserModule from "@/store/userModule";
import PasswordResetDialog from "@/components/dialogs/PasswordResetDialog.vue";
import { AuthorisationModel } from "@/api/generated";
import environment from '@/environment';

const userModule = getModule(UserModule);

@Component({ components: { PasswordResetDialog } })
export default class Login extends Validation {
  @Ref() readonly form!: VForm;

  private dialog = false;
  private loading = false;
  private model: AuthorisationModel = {
    emailAddress: "",
    password: ""
  };

  private async onSignIn() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await userModule.login(this.model);
      if(userModule.role === "CRW") {
        // await userModule.logout();
        window.location.replace(environment.APP_CLIENT_URL);
      } else if (userModule.role == "AGM") {
        this.$router.replace({ name: "Crew Member Overview" });
      } else {
        this.$router.replace({ name: "Dashboard" });
      }
    } finally {
      this.loading = false;
    }
  }
}
